/** @jsx jsx */
import { Link } from "gatsby"
import { jsx } from "theme-ui"
import replaceSlashes from "@lekoarts/gatsby-theme-minimal-blog/src/utils/replaceSlashes"
import useSiteMetadata from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-site-metadata"
import useMinimalBlogConfig from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-minimal-blog-config"

type Props = {
  isDark: boolean
}

const HeaderTitle = ({ isDark }: Props) => {
  const { siteTitle } = useSiteMetadata()
  const { siteHeadline } = useSiteMetadata()
  const { siteDescription } = useSiteMetadata()
  const { basePath } = useMinimalBlogConfig()
  const logoUrl = !isDark ? `url('/logo-light.svg')` : `url('/logo-dark.svg')`
  return (
    <Link
      to={replaceSlashes(`/${basePath}`)}
      aria-label={`${siteTitle} - Accueil`}
      sx={{ width: `200px`, color: `heading`, textDecoration: `none`, height: `100px`, backgroundRepeat: `no-repeat`, backgroundSize: `contain`, backgroundImage: logoUrl }}
    >
    </Link>
  )
}

export default HeaderTitle